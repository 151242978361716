<template>
  <div class="container-guarantee">
    <div>
      <UiIconsUser/>
      <p v-html="$t('guarantee.title.first')"></p>
    </div>
    <div>
      <UiIconsFrance/>
      <p v-html="$t('guarantee.title.second')"></p>
    </div>
    <div>
      <UiIconsBriefcase/>
      <p v-html="$t('guarantee.title.third')"></p>
    </div>
    <div>
      <UiIconsLockClosed/>
      <p v-html="$t('guarantee.title.fourth')"></p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container-guarantee {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-sand-0);
  gap: 1.5em;
  padding: 3em 2em 2em;

  @media (min-width: map-get($grid-breakpoints, md)) {
    flex-direction: row;
    align-items: flex-start;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    flex: 1 1 0;
    text-align: center;

    svg {
      font-size: 2.5rem;
    }

    p {
      margin-bottom: 0;
    }
  }
}
</style>
<script setup lang="ts">
</script>
