<script setup lang="ts">
</script>

<template>
  <NewsletterFooterSignIn
    id="footer-newsletter-container"
  />
  <GuaranteeBlock />
  <footer class="footer">
    <div class="container">
      <FooterTop />
      <FooterBottom />
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {

  @media (min-width: map-get($grid-breakpoints, lg)) {
    border-color: var(--color-navy-300);
    border-style: solid;
    border-width: 2px 0 0 0;
  }

  > .container {
    display: flex;
    flex-direction: column;
    padding: 2em 1em;

    @media (min-width: map-get($grid-breakpoints, md)) {
      padding: 2em 1.5em;
    }
  }
}
</style>
